import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import '@/assets/styles/style.scss'

Vue.config.productionTip = false

import VueScrollTo from 'vue-scrollto'
Vue.use(VueScrollTo, {
  container: 'body',
  duration: 500,
  easing: 'ease-in',
  force: true,
  onCancel: false,
  x: false
})

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
